<template lang="pug">
  Main#home
    section#showcase          
      #slick-wrapper
        slick(
          :options="slickOptions" @afterChange="handleAfterChange" @init="handleInit")
          .showcase-slide.slide-1
            .container
                .row.center-sm.center-xs
                  .col-lg-7.col-md-10.col-sm-10.col-xs-12.last-lg
                    .box-row
                      img(src="../../assets/images/Astronauts_hero.png")
                  .col-lg-5.start-xs.col-md-10.col-sm-10.col-xs-12
                    .box-row.start-lg.center-sm.start-xs
                      h1 {{ $t("showcase.slide_3.title") }}  
          .showcase-slide.slide-2
            .container
              .row.center-sm.center-xs
                .col-lg-6.col-md-10.col-sm-10.col-xs-12.last-lg
                  .box-row
                    img(src="../../assets/images/Ride_hero.png")
                .col-lg-6.col-md-10.col-sm-10.col-xs-12
                  .box-row.start-lg.center-sm.start-xs
                    h1 {{ $t("showcase.slide_1.title") }}
          .showcase-slide.slide-3
            .container
              .row.center-sm.center-xs
                .col-lg-6.col-md-10.col-sm-10.col-xs-12.last-lg
                  .box-row
                    img(src="../../assets/images/ETE_hero.png")
                .col-lg-6.start-xs.col-md-10.col-sm-10.col-xs-12
                  .box-row.start-lg.center-sm.start-xs
                    h1 {{ $t("showcase.slide_2.title") }}
        #slider-ui.container
          #slider-counter
            .counter__current-slider {{ showcase.current }}
            .counter__divider
            .counter__total-slider {{ showcase.total }}
          #slider-controls(v-if="!isMobile")
            .controls__prev
            .controls__divider
            .controls__next
    section#who-we-are.fadeIn
      .container
        .row.start-lg.center-md.center-xs
          .col-lg-12.col-md-10.col-sm-8.col-xs-10
            .box-row.fadeIn-item
              h2 {{ $t("home.who_we_are.title") }}
        .row.between-lg.start-lg.center-xs
          .col-lg-6.col-md-10.col-sm-8.col-xs-10
            .box-row.fadeIn-item
              p {{ $t("home.who_we_are.text_1") }}
          .col-lg-5.col-md-10.col-sm-8.col-xs-10
            .box-row.fadeIn-item
              p {{ $t("home.who_we_are.text_2") }}
        .row.center-xs
          .col
            .box-row.fadeIn-item
              //-router-link.button(:to="{name: 'about'}" exact) {{ $t("buttons.learn_more") }}
    section#expertise.fadeIn
      img(class="mountain", src="../../assets/images/Mountain.png")
      .container
        .row.center-lg.center-xs
          .col-lg-12.col-md-10.col-sm-8.col-xs-10
            .box-row.fadeIn-item
              h2 {{ $t("home.expertise.title") }}
            .box-row.fadeIn-item
              p {{ $t("home.expertise.text_1") }}
            .box-row.fadeIn-item
              p {{ $t("home.expertise.text_2") }}
        .row.center-lg.center-xs
          .col-lg-12.col-md-10.col-sm-8.col-xs-12
            #accordion(v-if="!isMobile")
              article.one
                h4 {{ $t("home.expertise.edtech.title") }}
                  i 01
                .hexagono
                  svg.hexagono-svg(width="100%" height="500px" viewBox="0 0 301 593" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none")
                    path(fill="#0b0096" fill-rule="evenodd" d="M 34 118 L 149 0 L 301 81 L 263 505 L 175 593 L 0 497 L 34 118 Z")
                  .content
                    .img
                      IconEdu
                    p {{ $t("home.expertise.edtech.text") }}
              article.two
                h4 {{ $t("home.expertise.iot.title") }}
                  i 02
                .hexagono
                  svg.hexagono-svg(width="100%" height="500px" viewBox="0 0 301 593" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none")
                    path(fill="#1100e8" fill-rule="evenodd" d="M 34 118 L 149 0 L 301 81 L 263 505 L 175 593 L 0 497 L 34 118 Z")
                  .content
                    .img
                      IconIot
                    p {{ $t("home.expertise.iot.text") }}
              article.three
                h4 {{ $t("home.expertise.software.title") }}
                  i 03
                .hexagono
                  svg.hexagono-svg(width="100%" height="500px" viewBox="0 0 301 593" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none")
                    path(fill="#3520fb" fill-rule="evenodd" d="M 34 118 L 149 0 L 301 81 L 263 505 L 175 593 L 0 497 L 34 118 Z")
                  .content
                    .img
                      IconDev
                    p {{ $t("home.expertise.software.text") }}
              article.four
                h4 {{ $t("home.expertise.ar_vr.title") }}
                  i 04
                .hexagono
                  svg.hexagono-svg(width="100%" height="500px" viewBox="0 0 301 593" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none")
                    path(fill="#0091ff" fill-rule="evenodd" d="M 34 118 L 149 0 L 301 81 L 263 505 L 175 593 L 0 497 L 34 118 Z")
                  .content
                    .img
                      IconArVr
                    p {{ $t("home.expertise.ar_vr.text") }}
            #slider-expertise(v-if="isMobile")
              slick(:options="mobileSlickOptions")
                .slide
                  .row.center-sm.center-xs
                    .col-sm-8.col-xs-10
                      .content
                        h4 {{ $t("home.expertise.edtech.title") }}
                          i 01
                        .img
                          IconEdu
                        p {{ $t("home.expertise.edtech.text") }}
                      svg.hexagono-svg(width="100%" height="500px" viewBox="0 0 301 593" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none")
                        path(fill="#0b0096" fill-rule="evenodd" d="M 34 118 L 149 0 L 301 81 L 263 505 L 175 593 L 0 497 L 34 118 Z")
                .slide
                  .row.center-sm.center-xs
                    .col-sm-8.col-xs-10
                      .content
                        h4 {{ $t("home.expertise.iot.title") }}
                          i 02
                        .img
                          IconIot
                        p {{ $t("home.expertise.iot.text") }}
                      svg.hexagono-svg(width="100%" height="500px" viewBox="0 0 301 593" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none")
                        path(fill="#1100e8" fill-rule="evenodd" d="M 34 118 L 149 0 L 301 81 L 263 505 L 175 593 L 0 497 L 34 118 Z")
                .slide
                  .row.center-sm.center-xs
                    .col-sm-8.col-xs-10
                      .content
                        h4 {{ $t("home.expertise.software.title") }}
                          i 03
                        .img
                          IconDev
                        p {{ $t("home.expertise.software.text") }}
                      svg.hexagono-svg(width="100%" height="500px" viewBox="0 0 301 593" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none")
                        path(fill="#3520fb" fill-rule="evenodd" d="M 34 118 L 149 0 L 301 81 L 263 505 L 175 593 L 0 497 L 34 118 Z")
                .slide
                  .row.center-sm.center-xs
                    .col-sm-8.col-xs-10
                      .content
                        h4 {{ $t("home.expertise.ar_vr.title") }}
                          i 04
                        .img
                          IconArVr
                        p {{ $t("home.expertise.ar_vr.text") }}
                      svg.hexagono-svg(width="100%" height="500px" viewBox="0 0 301 593" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none")
                        path(fill="#0091ff" fill-rule="evenodd" d="M 34 118 L 149 0 L 301 81 L 263 505 L 175 593 L 0 497 L 34 118 Z")
    section#our-team.fadeIn
      .container
        .row.start-lg.center-xs
          .col-lg-6.col-md-10.col-sm-8.col-xs-10
            .box-row.fadeIn-item
              h2 {{ $t("home.our_team.title") }}
            .box-row.fadeIn-item
              p {{ $t("home.our_team.text_1") }}
            .box-row.fadeIn-item
              p {{ $t("home.our_team.text_2") }}
            .box-row.fadeIn-item
              //-router-link.button(:to="{name: 'about'}" exact) {{ $t("buttons.learn_more") }}
          .col-lg-6.col-md-10.col-sm-10.col-xs-12
            #astronauts.box-row
              img(class="astronauta floating", src="../../assets/images/astronauta.png")
              img(class="astronauta-vr floating", src="../../assets/images/astronauta-vr.png")
              img(class="astronauta-satelite floating", src="../../assets/images/astronauta-satelite.png")
              img(class="meteorito-2 float-rotate", src="../../assets/images/meteorito02.svg")
              img(class="meteorito-3 float-rotate", src="../../assets/images/meteorito03.svg")
              img(class="meteorito-4 float-rotate", src="../../assets/images/meteorito04.svg")
    section#clients.fadeIn
      .container
        .row.center-xs
          .col-lg-10.col-sm-8.col-xs-10
            h2.fadeIn-item {{ $t("home.clients.title") }}
            p.fadeIn-item {{ $t("home.clients.text") }}
          .col-lg-12.col-md-10.col-sm-8.col-xs-10
            .grid.fadeIn-grid
              div
                img.fadeIn-grid-item(src='../../assets/images/clients/client_acme.svg' alt='Acme')
              div
                img.fadeIn-grid-item(src='../../assets/images/clients/client_age_of_learning.png' alt='Age of Learning')
              div
                img.fadeIn-grid-item(src='../../assets/images/clients/client_FTD.png' alt='FTD')
              div
                img.fadeIn-grid-item(src='../../assets/images/clients/client_saraiva.png' alt='Saraiva')
              div
                img.fadeIn-grid-item(src='../../assets/images/clients/client_abril.svg' alt='Abril')
              div
                img.fadeIn-grid-item(src='../../assets/images/clients/client_endemol.svg' alt='Endemol')
              div
                img.fadeIn-grid-item(src='../../assets/images/clients/client_record.png' alt='Record TV')
              div
                img.fadeIn-grid-item(src='../../assets/images/clients/client_UOL.svg' alt='UOL')
              div
                img.fadeIn-grid-item(src='../../assets/images/clients/client_bayer.svg' alt='Bayer')
              div
                img.fadeIn-grid-item(src='../../assets/images/clients/client_boeing.png' alt='Boeing')
              div
                img.fadeIn-grid-item(src='../../assets/images/clients/client_defense.png' alt='Defense seal')
              div
                img.fadeIn-grid-item(src='../../assets/images/clients/client_kero_coco.png' alt='Kero Coco')
              div
                img.fadeIn-grid-item(src='../../assets/images/clients/client_rabbit.svg' alt='Rabbit')
              div
                img.fadeIn-grid-item(src='../../assets/images/clients/client_relation_up.svg' alt='RelationUp')
              div
                img.fadeIn-grid-item(src='../../assets/images/clients/client_rice.svg' alt='Rice')
              div
                img.fadeIn-grid-item(src='../../assets/images/clients/client_ride.svg' alt='Ride')
              div
                img.fadeIn-grid-item(src='../../assets/images/clients/client_siga_me.svg' alt='SigaMe')
              div
                img.fadeIn-grid-item(src='../../assets/images/clients/client_stratify.svg' alt='Stratify')
              div
                img.fadeIn-grid-item(src='../../assets/images/clients/client_true_rev.png' alt='True Rev')
              div
                img.fadeIn-grid-item(src='../../assets/images/clients/client_cg_blockchain.png' alt='CG BlockChain')
              div
                img.fadeIn-grid-item(src='../../assets/images/clients/client_mattervr.png' alt='Matter VR')
    section#cases.fadeIn
      .container
        #tabs-cases(v-if="!isMobile")
          .tabs
            .grid
              .row.start-lg.center-xs
                .col-lg-3.fadeIn-item
                  .menu
                    .active
                      h4 Ride
                        i 01
                    div
                      h4 ETE FMC
                        i 02
                .col-lg-6.fadeIn-item
                  ul.tab
                    li.active
                      div
                        img(class="case-image", src="../../assets/images/cases_Ride.png")
                    li
                      div
                        img(class="case-image", src="../../assets/images/cases_ETE.png")
                .col-lg-3.fadeIn-item
                  div
                    h2 {{ $t("home.cases.title") }}
                    p {{ $t("home.cases.text_1") }}
                    p {{ $t("home.cases.text_2") }}
        #slider-cases(v-else)
          .row.center-xs
            .col-sm-8.col-xs-10
              h2 {{ $t("home.cases.title") }}
              p {{ $t("home.cases.text_1") }}
              p {{ $t("home.cases.text_2") }}
          slick(:options="mobileSlickOptions")
            .slide
              .row.center-xs
                .col-sm-6.col-xs-10
                  h4 Ride
                    i 01
                  img(class="case-image", src="../../assets/images/cases_Ride.png")
            .slide
              .row.center-xs
                .col-sm-6.col-xs-10
                  h4 ETE FMC
                    i 02
                  img(class="case-image", src="../../assets/images/cases_ETE.png")
    section#contact
      ContactSection
</template>

<script>
import Main from "../Template/Main";
import ContactSection from "../../components/ContactSection/ContactSection";
import IconEdu from "../../components/AnimatedIcons/IconEdu";
import IconIot from "../../components/AnimatedIcons/IconIot";
import IconDev from "../../components/AnimatedIcons/IconDev";
import IconArVr from "../../components/AnimatedIcons/IconArVr";
import Slick from "vue-slick";

export default {
  name: "Home",
  components: {
    Main,
    ContactSection,
    Slick,
    IconEdu,
    IconIot,
    IconDev,
    IconArVr
  },
  data() {
    return {
      isMobile: false,
      slickOptions: {
        slidesToShow: 1,
        speed: 800,
        autoplay: true,
        autoplaySpeed: 3000,
        infinite: false
      },
      mobileSlickOptions: {
        slidesToShow: 1,
        arrows: false,
        infinite: false,
        adaptiveHeight: true,
        dots: true
      },
      showcase: {
        total: 0,
        current: 0
      },
      scenes: []
    };
  },
  methods: {
    mobileBreakpointCheck() {
      let vm = this;
      let breakPoint = 1023;
      if (window.innerWidth > breakPoint) {
        vm.isMobile = false;
      } else {
        vm.isMobile = true;
      }
    },
    // Events listeners
    handleAfterChange(event, slick, currentSlide) {
      let vm = this;
      vm.showcase.current = ("0" + (currentSlide + 1)).slice(-2);
    },
    handleInit(event, slick) {
      let vm = this;
      vm.showcase.total = ("0" + slick.slideCount).slice(-2);
      vm.showcase.current = ("0" + (slick.currentSlide + 1)).slice(-2);
    }
  },
  created() {
    let vm = this;
    // Accordion
    $(document).on("click", ".tabs .menu div", function() {
      var numberIndex = $(this).index();

      if (!$(this).is("active")) {
        $(".tabs .menu div").removeClass("active");
        $(".tabs ul li").removeClass("active");

        $(this).addClass("active");
        $(".tabs ul")
          .find("li:eq(" + numberIndex + ")")
          .addClass("active");
      }
    });
    $(window).resize(function() {
      vm.mobileBreakpointCheck();
    });
  },
  mounted() {
    let vm = this;
    vm.mobileBreakpointCheck();
    $("#showcase .slick-next").appendTo("#slider-controls");
    $("#showcase .slick-prev").appendTo("#slider-controls");

    // Fade in Animations
    $(".fadeIn").each(function() {
      const scene = vm.$scrollmagic.scene({
        triggerElement: this,
        offset: -150,
        reverse: true
      });

      vm.$scrollmagic.addScene(
        scene.setTween(
          TweenMax.staggerFrom(
            $(this).find(".fadeIn-item"),
            1,
            {
              y: 40,
              autoAlpha: 0,
              delay: 0,
              ease: Power4.easeOut
            },
            0.2
          )
        )
      );
    });
    $(".fadeIn-grid").each(function() {
      const scene = vm.$scrollmagic.scene({
        triggerElement: this,
        offset: -150,
        reverse: true
      });

      vm.$scrollmagic.addScene(
        scene.setTween(
          TweenMax.staggerFrom(
            $(this).find(".fadeIn-grid-item"),
            1,
            {
              y: 40,
              autoAlpha: 0,
              delay: 0,
              ease: Power4.easeOut
            },
            0.05
          )
        )
      );
    });
    /* who-we-are */
    const sceneWhoWeAre = vm.$scrollmagic.scene({
      triggerElement: "#who-we-are",
      reverse: true,
      duration: $("#who-we-are").height()
    });
    vm.$scrollmagic.addScene(
      sceneWhoWeAre.setClassToggle("#home", "who-we-are")
    );

    /* expertise */
    const sceneExpertise = vm.$scrollmagic.scene({
      triggerElement: "#expertise",
      reverse: true,
      duration: $("#expertise").height()
    });
    vm.$scrollmagic.addScene(
      sceneExpertise.setClassToggle("#home", "expertise")
    );

    /* our-team */
    const sceneOurTeam = vm.$scrollmagic.scene({
      triggerElement: "#our-team",
      reverse: true,
      duration: $("#our-team").height()
    });
    vm.$scrollmagic.addScene(sceneOurTeam.setClassToggle("#home", "our-team"));

    /* clients */
    const sceneClients = vm.$scrollmagic.scene({
      triggerElement: "#clients",
      reverse: true,
      duration: $("#clients").height()
    });
    vm.$scrollmagic.addScene(sceneClients.setClassToggle("#home", "clients"));

    /* cases */
    const sceneCases = vm.$scrollmagic.scene({
      triggerElement: "#cases",
      reverse: true,
      duration: $("#cases").height()
    });
    vm.$scrollmagic.addScene(sceneCases.setClassToggle("#home", "cases"));

    vm.$bus.$emit("load", false);
  }
};
</script>

<style lang="scss">
@import '../../assets/styles/main';
main {
  background-color: #000;
  &.who-we-are {
    background-color: $blue95;
  }
  &.expertise {
    background-color: $blue68;
  }
  &.our-team {
    background-color: $blue92;
  }
  &.clients {
    background-color: $blue95;
  }
  &.cases {
    background-color: $blue92;
  }
}
/* Section Showcase */
#showcase {
  padding: 0 !important;
  #slick-wrapper {
    display: block;
    overflow: hidden;
    width: 100vw;
    height: 100vh;
    position: relative;
    z-index: 5;
    @media screen and (max-width: 1200px) {
      .slick-prev,
      .slick-next {
        display: none !important;
      }
    }
    .showcase-slide {
      display: block;
      height: 100vh;
      min-height: 100vh;
      &.slide-1 {
        background-image: url("../../assets/images/Planet_hero.png");
        background-position: bottom left;
        background-repeat: no-repeat;
        background-size: 80vw;
        @media screen and (max-width: 1200px) {
          background-image: url("../../assets/images/Planet_hero_mobile.png");
        }
      }
      &.slide-2 {
        background: $gradient-blue;
      }
      &.slide-3 {
        background: $gradient-green;
      }
      .container {
        height: 100vh;
        box-sizing: border-box;
        padding: 20vh 0;
        @media screen and (max-width: 1366px) {
          padding: 20vh 10vw;
        }
        img {
          width: 100%;
          object-fit: contain;
          @media screen and (max-width: 1200px) {
            max-height: 300px;
            height: 30vh;
          }
        }
        h1 {
          margin-top: 10vh;
        }
      }
    }
    #slider-ui {
      position: absolute;
      left: 50%;
      bottom: 0;
      padding: 0;
      transform: translateX(-50%);
      z-index: 10;
      #slider-counter {
        position: absolute;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: $white;
        font-size: 1.4rem;
        font-weight: 300;
        left: 200px;
        bottom: 15vh;
        height: 40px;
        text-align: center;
        width: calc(8vw + 80px);
        @media screen and (max-width: 1200px) {
          bottom: 42vh;
          left: 50vw;
          transform: translateX(-50%);
        }
        @media screen and (max-width: 700px) {
          bottom: 42vh;
          left: 10vw;
          transform: translateX(0);
        }
        .counter__current-slider {
          display: flex;
          width: 30px;
        }
        .counter__divider {
          display: flex;
          height: 2px;
          width: 8vw;
          background: rgba(255, 255, 255, 0.3);
          vertical-align: middle;
        }
        .counter__total-slider {
          display: flex;
          width: 30px;
        }
      }
      #slider-controls {
        position: absolute;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: $white;
        font-size: 22px;
        font-weight: bold;
        height: 40px;
        right: 200px;
        bottom: 15vh;
        .controls__prev {
          display: flex;
          width: 40px;
          height: 40px;
        }
        .controls__divider {
          display: flex;
          height: 2px;
          width: 8vw;
          background: rgba(255, 255, 255, 0.3);
          vertical-align: middle;
        }
        .controls__next {
          display: flex;
          width: 40px;
          height: 40px;
        }
      }
    }
  }
}
/* Section who-we-are */
#who-we-are {
  min-height: 50vh;
  position: relative;
}
/* Section Clients */
#clients {
  .grid {
    margin-top: 4rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    div {
      width: 16.66666%;
      margin: 2rem 0;
      img {
        display: block;
        margin: auto;
        height: 4rem;
        width: 100%;
        max-width: 8rem;
        object-fit: contain;
      }
    }
  }
  @media screen and (max-width: 768px) {
    .grid {
      margin-top: 2rem;
      div {
        width: 33.3333%;
        margin: 1.5rem 0;
        img {
          display: block;
          margin: auto;
          height: 2.5rem;
          width: 80%;
          object-fit: contain;
        }
      }
    }
  }
}
/* Section Expertise */
#expertise {
  .mountain {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 0;
  }
  #accordion {
    width: 100%;
    height: 600px;
    margin-top: 3rem;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    position: relative;
    justify-content: space-between;
    overflow: hidden;
    transition: all 500ms ease 500ms;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    article {
      width: 25%;
      height: 100%;
      text-align: center;
      color: $white;
      box-sizing: border-box;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.5s ease 0.1s;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      .hexagono {
        text-align: center;
        display: flex;
        height: 500px;
        width: 100%;
        position: relative;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        .hexagono-svg path {
          transition: all 0.5s ease 0.1s;
        }
        .content {
          transition: 200ms ease 100ms;
          position: absolute;
          top: 80px;
          width: 80%;
          left: 50%;
          transform: translate(-50%, 0);
          -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
          .img {
            width: 40%;
            display: block;
            margin: 0 auto;
          }
          p {
            font-size: 1rem;
            line-height: 1.2rem;
            font-weight: 300;
            opacity: 0;
          }
        }
      }
    }
    h4 {
      text-transform: uppercase;
      line-height: 2rem;
      top: 25%;
      left: 23%;
      display: inline-block;
      position: absolute;
      text-align: left;
      margin: 0;
      font-size: 1.6rem;
      z-index: 2;
      transition: 400ms linear 200ms;
      font-weight: 600;
      i {
        display: inline-block;
        position: absolute;
        top: 0;
        right: -1.6rem;
        font-size: 0.8rem;
        line-height: 1.6rem;
      }
    }
    /* Hover Animations */
    &:hover article {
      width: 18%;
    }
    article:hover {
      width: 46%;
      h4 {
        transform: translate(0, -2rem);
      }
      .hexagono {
        svg.hexagono-svg {
          path {
            d: path(
              "M 14 118 L 140 0 L 301 81 L 287 505 L 160 593 L 0 497 L 14 118 Z"
            );
          }
        }
        .content p {
          opacity: 1;
          transition: 400ms linear 600ms;
        }
      }
      .svg_edu_0 {
        animation: svg_draw 0.8s ease-in 0ms forwards;
      }
      .svg_edu_1 {
        animation: svg_draw 0.8s ease-in 40ms forwards;
      }
      .svg_edu_2 {
        animation: svg_draw 0.8s ease-in 80ms forwards;
      }
      .svg_edu_3 {
        animation: svg_draw 0.8s ease-in 120ms forwards;
      }
      .svg_edu_4 {
        animation: svg_draw 0.8s ease-in 160ms forwards;
      }
      .svg_edu_5 {
        animation: svg_draw 0.8s ease-in 200ms forwards;
      }
      .svg_edu_6 {
        animation: svg_draw 0.8s ease-in 240ms forwards;
      }
      .svg_edu_7 {
        animation: svg_draw 0.8s ease-in 280ms forwards;
      }
      .svg_edu_8 {
        animation: svg_draw 0.8s ease-in 320ms forwards;
      }
      .svg_edu_9 {
        animation: svg_draw 0.8s ease-in 360ms forwards;
      }
      .svg_edu_10 {
        animation: svg_draw 0.8s ease-in 400ms forwards;
      }
      .svg_iot_0 {
        animation: svg_draw 0.8s ease-in 444ms forwards;
      }
      .svg_iot_1 {
        animation: svg_draw 0.8s ease-in 444ms forwards;
      }
      .svg_iot_2 {
        animation: svg_draw 0.8s ease-in 39ms forwards;
      }
      .svg_iot_3 {
        animation: svg_draw 0.8s ease-in 58ms forwards;
      }
      .svg_iot_4 {
        animation: svg_draw 0.8s ease-in 78ms forwards;
      }
      .svg_iot_5 {
        animation: svg_draw 0.8s ease-in 98ms forwards;
      }
      .svg_iot_6 {
        animation: svg_draw 0.8s ease-in 117ms forwards;
      }
      .svg_iot_7 {
        animation: svg_draw 0.8s ease-in 137ms forwards;
      }
      .svg_iot_8 {
        animation: svg_draw 0.8s ease-in 444ms forwards;
      }
      .svg_iot_9 {
        animation: svg_draw 0.8s ease-in 176ms forwards;
      }
      .svg_iot_10 {
        animation: svg_draw 0.8s ease-in 196ms forwards;
      }
      .svg_iot_11 {
        animation: svg_draw 0.8s ease-in 215ms forwards;
      }
      .svg_iot_12 {
        animation: svg_draw 0.8s ease-in 235ms forwards;
      }
      .svg_iot_13 {
        animation: svg_draw 0.8s ease-in 254ms forwards;
      }
      .svg_iot_14 {
        animation: svg_draw 0.8s ease-in 274ms forwards;
      }
      .svg_iot_15 {
        animation: svg_draw 0.8s ease-in 294ms forwards;
      }
      .svg_iot_16 {
        animation: svg_draw 0.8s ease-in 313ms forwards;
      }
      .svg_iot_17 {
        animation: svg_draw 0.8s ease-in 333ms forwards;
      }
      .svg_dev_0 {
        animation: svg_draw 0.8s ease-in 0ms forwards;
      }
      .svg_dev_1 {
        animation: svg_draw 0.8s ease-in 18ms forwards;
      }
      .svg_dev_2 {
        animation: svg_draw 0.8s ease-in 37ms forwards;
      }
      .svg_dev_3 {
        animation: svg_draw 0.8s ease-in 55ms forwards;
      }
      .svg_dev_4 {
        animation: svg_draw 0.8s ease-in 74ms forwards;
      }
      .svg_dev_5 {
        animation: svg_draw 0.8s ease-in 92ms forwards;
      }
      .svg_dev_6 {
        animation: svg_draw 0.8s ease-in 111ms forwards;
      }
      .svg_dev_7 {
        animation: svg_draw 0.8s ease-in 129ms forwards;
      }
      .svg_dev_8 {
        animation: svg_draw 0.8s ease-in 148ms forwards;
      }
      .svg_dev_9 {
        animation: svg_draw 0.8s ease-in 166ms forwards;
      }
      .svg_dev_10 {
        animation: svg_draw 0.8s ease-in 185ms forwards;
      }
      .svg_dev_11 {
        animation: svg_draw 0.8s ease-in 203ms forwards;
      }
      .svg_dev_12 {
        animation: svg_draw 0.8s ease-in 222ms forwards;
      }
      .svg_dev_13 {
        animation: svg_draw 0.8s ease-in 240ms forwards;
      }
      .svg_dev_14 {
        animation: svg_draw 0.8s ease-in 259ms forwards;
      }
      .svg_dev_15 {
        animation: svg_draw 0.8s ease-in 277ms forwards;
      }
      .svg_dev_16 {
        animation: svg_draw 0.8s ease-in 296ms forwards;
      }
      .svg_dev_17 {
        animation: svg_draw 0.8s ease-in 314ms forwards;
      }
      .svg_dev_18 {
        animation: svg_draw 0.8s ease-in 333ms forwards;
      }
      .svg_arvr_0 {
        animation: svg_draw 0.8s ease-in 0ms forwards;
      }
      .svg_arvr_1 {
        animation: svg_draw 0.8s ease-in 25ms forwards;
      }
      .svg_arvr_2 {
        animation: svg_draw 0.8s ease-in 51ms forwards;
      }
      .svg_arvr_3 {
        animation: svg_draw 0.8s ease-in 76ms forwards;
      }
      .svg_arvr_4 {
        animation: svg_draw 0.8s ease-in 102ms forwards;
      }
      .svg_arvr_5 {
        animation: svg_draw 0.8s ease-in 128ms forwards;
      }
      .svg_arvr_6 {
        animation: svg_draw 0.8s ease-in 153ms forwards;
      }
      .svg_arvr_7 {
        animation: svg_draw 0.8s ease-in 179ms forwards;
      }
      .svg_arvr_8 {
        animation: svg_draw 0.8s ease-in 205ms forwards;
      }
      .svg_arvr_9 {
        animation: svg_draw 0.8s ease-in 230ms forwards;
      }
      .svg_arvr_10 {
        animation: svg_draw 0.8s ease-in 256ms forwards;
      }
      .svg_arvr_11 {
        animation: svg_draw 0.8s ease-in 282ms forwards;
      }
      .svg_arvr_12 {
        animation: svg_draw 0.8s ease-in 307ms forwards;
      }
      .svg_arvr_13 {
        animation: svg_draw 0.8s ease-in 333ms forwards;
      }
    }
  }
  #slider-expertise {
    width: 100%;
    position: relative;
    padding: 2rem 0;
    box-sizing: border-box;
    display: block;
    min-height: 0;
    min-width: 0;
    .slide {
      position: relative;
      .content {
        position: relative;
        width: 100%;
        display: block;
        box-sizing: border-box;
        padding: 5rem 2rem;
        z-index: 2;
        h4 {
          text-transform: uppercase;
          position: relative;
          line-height: 1.6rem;
          display: inline-block;
          text-align: left;
          margin: 0;
          font-size: 1.6rem;
          font-weight: 600;
          i {
            display: inline-block;
            position: absolute;
            top: 0;
            right: -1.6rem;
            font-size: 0.8rem;
            line-height: 1rem;
          }
        }
        .img {
          width: 70%;
          margin: auto;
        }
        p {
          font-size: 1rem;
          line-height: 1.6rem;
          font-weight: 300;
        }
      }
      .hexagono-svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
    .slick-slide.slick-active {
      .svg_edu_0 {
        animation: svg_draw 0.8s ease-in 0ms forwards;
      }
      .svg_edu_1 {
        animation: svg_draw 0.8s ease-in 40ms forwards;
      }
      .svg_edu_2 {
        animation: svg_draw 0.8s ease-in 80ms forwards;
      }
      .svg_edu_3 {
        animation: svg_draw 0.8s ease-in 120ms forwards;
      }
      .svg_edu_4 {
        animation: svg_draw 0.8s ease-in 160ms forwards;
      }
      .svg_edu_5 {
        animation: svg_draw 0.8s ease-in 200ms forwards;
      }
      .svg_edu_6 {
        animation: svg_draw 0.8s ease-in 240ms forwards;
      }
      .svg_edu_7 {
        animation: svg_draw 0.8s ease-in 280ms forwards;
      }
      .svg_edu_8 {
        animation: svg_draw 0.8s ease-in 320ms forwards;
      }
      .svg_edu_9 {
        animation: svg_draw 0.8s ease-in 360ms forwards;
      }
      .svg_edu_10 {
        animation: svg_draw 0.8s ease-in 400ms forwards;
      }
      .svg_iot_0 {
        animation: svg_draw 0.8s ease-in 444ms forwards;
      }
      .svg_iot_1 {
        animation: svg_draw 0.8s ease-in 444ms forwards;
      }
      .svg_iot_2 {
        animation: svg_draw 0.8s ease-in 39ms forwards;
      }
      .svg_iot_3 {
        animation: svg_draw 0.8s ease-in 58ms forwards;
      }
      .svg_iot_4 {
        animation: svg_draw 0.8s ease-in 78ms forwards;
      }
      .svg_iot_5 {
        animation: svg_draw 0.8s ease-in 98ms forwards;
      }
      .svg_iot_6 {
        animation: svg_draw 0.8s ease-in 117ms forwards;
      }
      .svg_iot_7 {
        animation: svg_draw 0.8s ease-in 137ms forwards;
      }
      .svg_iot_8 {
        animation: svg_draw 0.8s ease-in 444ms forwards;
      }
      .svg_iot_9 {
        animation: svg_draw 0.8s ease-in 176ms forwards;
      }
      .svg_iot_10 {
        animation: svg_draw 0.8s ease-in 196ms forwards;
      }
      .svg_iot_11 {
        animation: svg_draw 0.8s ease-in 215ms forwards;
      }
      .svg_iot_12 {
        animation: svg_draw 0.8s ease-in 235ms forwards;
      }
      .svg_iot_13 {
        animation: svg_draw 0.8s ease-in 254ms forwards;
      }
      .svg_iot_14 {
        animation: svg_draw 0.8s ease-in 274ms forwards;
      }
      .svg_iot_15 {
        animation: svg_draw 0.8s ease-in 294ms forwards;
      }
      .svg_iot_16 {
        animation: svg_draw 0.8s ease-in 313ms forwards;
      }
      .svg_iot_17 {
        animation: svg_draw 0.8s ease-in 333ms forwards;
      }
      .svg_dev_0 {
        animation: svg_draw 0.8s ease-in 0ms forwards;
      }
      .svg_dev_1 {
        animation: svg_draw 0.8s ease-in 18ms forwards;
      }
      .svg_dev_2 {
        animation: svg_draw 0.8s ease-in 37ms forwards;
      }
      .svg_dev_3 {
        animation: svg_draw 0.8s ease-in 55ms forwards;
      }
      .svg_dev_4 {
        animation: svg_draw 0.8s ease-in 74ms forwards;
      }
      .svg_dev_5 {
        animation: svg_draw 0.8s ease-in 92ms forwards;
      }
      .svg_dev_6 {
        animation: svg_draw 0.8s ease-in 111ms forwards;
      }
      .svg_dev_7 {
        animation: svg_draw 0.8s ease-in 129ms forwards;
      }
      .svg_dev_8 {
        animation: svg_draw 0.8s ease-in 148ms forwards;
      }
      .svg_dev_9 {
        animation: svg_draw 0.8s ease-in 166ms forwards;
      }
      .svg_dev_10 {
        animation: svg_draw 0.8s ease-in 185ms forwards;
      }
      .svg_dev_11 {
        animation: svg_draw 0.8s ease-in 203ms forwards;
      }
      .svg_dev_12 {
        animation: svg_draw 0.8s ease-in 222ms forwards;
      }
      .svg_dev_13 {
        animation: svg_draw 0.8s ease-in 240ms forwards;
      }
      .svg_dev_14 {
        animation: svg_draw 0.8s ease-in 259ms forwards;
      }
      .svg_dev_15 {
        animation: svg_draw 0.8s ease-in 277ms forwards;
      }
      .svg_dev_16 {
        animation: svg_draw 0.8s ease-in 296ms forwards;
      }
      .svg_dev_17 {
        animation: svg_draw 0.8s ease-in 314ms forwards;
      }
      .svg_dev_18 {
        animation: svg_draw 0.8s ease-in 333ms forwards;
      }
      .svg_arvr_0 {
        animation: svg_draw 0.8s ease-in 0ms forwards;
      }
      .svg_arvr_1 {
        animation: svg_draw 0.8s ease-in 25ms forwards;
      }
      .svg_arvr_2 {
        animation: svg_draw 0.8s ease-in 51ms forwards;
      }
      .svg_arvr_3 {
        animation: svg_draw 0.8s ease-in 76ms forwards;
      }
      .svg_arvr_4 {
        animation: svg_draw 0.8s ease-in 102ms forwards;
      }
      .svg_arvr_5 {
        animation: svg_draw 0.8s ease-in 128ms forwards;
      }
      .svg_arvr_6 {
        animation: svg_draw 0.8s ease-in 153ms forwards;
      }
      .svg_arvr_7 {
        animation: svg_draw 0.8s ease-in 179ms forwards;
      }
      .svg_arvr_8 {
        animation: svg_draw 0.8s ease-in 205ms forwards;
      }
      .svg_arvr_9 {
        animation: svg_draw 0.8s ease-in 230ms forwards;
      }
      .svg_arvr_10 {
        animation: svg_draw 0.8s ease-in 256ms forwards;
      }
      .svg_arvr_11 {
        animation: svg_draw 0.8s ease-in 282ms forwards;
      }
      .svg_arvr_12 {
        animation: svg_draw 0.8s ease-in 307ms forwards;
      }
      .svg_arvr_13 {
        animation: svg_draw 0.8s ease-in 333ms forwards;
      }
    }
  }
}
/* Section our-team*/
#our-team {
  #astronauts {
    width: 100%;
    position: relative;
    &:after {
      content: "";
      display: block;
      padding-bottom: 100%;
    }
    img {
      position: absolute;
      &.astronauta {
        animation-delay: 1400ms;
        width: 18%;
        top: 0;
        left: 10%;
      }
      &.astronauta-vr {
        width: 30%;
        top: 45%;
        left: 0;
      }
      &.astronauta-satelite {
        animation-delay: 800ms;
        width: 100%;
        left: 0;
      }
      &.meteorito-2 {
        bottom: 0;
        right: 0;
        width: 12%;
        animation-delay: 4s;
        animation-duration: 30s;
      }
      &.meteorito-3 {
        top: 30%;
        right: 0;
        width: 20%;
        animation-delay: 1s;
      }
      &.meteorito-4 {
        bottom: 5%;
        left: 0%;
        width: 15%;
        animation-delay: 7s;
        animation-direction: reverse;
      }
    }
  }
}
/* Section Cases */
#cases {
  .tabs {
    position: relative;
    .menu div {
      padding: 15px 0px;
      line-height: 1.6rem;
      font-size: 1.4rem;
      text-transform: uppercase;
      margin-bottom: 10px;
      color: $white;
      cursor: pointer;
      position: relative;
      vertical-align: middle;
      transition: 400ms;
      &:hover {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      }
      &.active {
        color: $orange39;
      }
      i {
        display: inline-block;
        font-size: 0.8rem;
        line-height: 1.6rem;
        padding-left: 1rem;
        vertical-align: top;
      }
    }
  }
  ul.tab {
    position: relative;
    height: 500px;
    transition: 0.5s all cubic-bezier(0.075, 0.82, 0.165, 1);
    li {
      opacity: 0;
      transform: translateY(20px);
      position: absolute;
      transition: 1s all cubic-bezier(0.075, 0.82, 0.165, 1);
      img.case-image {
        width: 100%;
        display: block;
      }
      &.active {
        transition-delay: 0.3s;
        z-index: 2;
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
  #slider-cases {
    .slide {
      display: block;
      width: 100%;
      position: relative;
      h4 {
        color: $orange39;
        text-transform: uppercase;
        font-weight: 600;
        position: relative;
        display: inline-block;
        font-size: 1.8rem;
        line-height: 2rem;
        margin: 2rem 0;
        i {
          position: absolute;
          top: 0;
          right: -1.6rem;
          font-size: 0.8rem;
          line-height: 1rem;
        }
      }
      img {
        display: block;
        width: 100%;
      }
    }
  }
}
</style>