<template lang="pug">
  div
    svg(xmlns='http://www.w3.org/2000/svg', fill-opacity='0', fill-rule='evenodd', stroke-miterlimit='1.5', stroke-linejoin='round', viewBox='0 0 180 181', style='')
        path.svg_arvr_0(fill='#123D6E', stroke='#123D6E', stroke-width='1', d='M165.152 78.359c-41-8.68-103.77-10.32-142.61-1.521l112.61 65.082h30V78.359z')
        path.svg_arvr_1(fill='#F3EB7D', stroke='#F3EB7D', stroke-width='1', d='M135.152 78.359c-41-8.68-80.94-8.799-119.78 0v63.561c39.93-2.88 79.86-2.521 119.78 0V78.359z')
        path.svg_arvr_2(fill='#E85E36', stroke='#E85E36', stroke-width='1', d='M81.683 107.296c0-13.335 9.565-24.162 21.346-24.162s21.346 10.827 21.346 24.162c0 13.335-9.565 24.161-21.346 24.161s-21.346-10.826-21.346-24.161z')
        path.svg_arvr_3(fill='none', stroke='#F3B85C', stroke-width='2.6', d='M91.36 107.297c0-9.335 6.65-16.913 14.841-16.913 8.19 0 14.84 7.578 14.84 16.913 0 9.334-6.65 16.912-14.84 16.912-8.191 0-14.841-7.578-14.841-16.912z')
        path.svg_arvr_4(fill='none', stroke='#F3B85C', stroke-width='1.8', d='M95.705 107.296c0-6.6 4.701-11.957 10.491-11.957 5.791 0 10.492 5.357 10.492 11.957 0 6.599-4.701 11.956-10.492 11.956-5.79 0-10.491-5.357-10.491-11.956z')
        path.svg_arvr_5(fill='#135D8C', stroke='#135D8C', stroke-width='1', d='M22.683 107.296c0-13.335 9.565-24.162 21.346-24.162s21.346 10.827 21.346 24.162c0 13.335-9.565 24.161-21.346 24.161s-21.346-10.826-21.346-24.161z')
        path.svg_arvr_6(fill='none', stroke='#17BBDD', stroke-width='2.6', d='M32.361 107.297c0-9.335 6.649-16.913 14.84-16.913 8.19 0 14.84 7.578 14.84 16.913 0 9.334-6.65 16.912-14.84 16.912-8.191 0-14.84-7.578-14.84-16.912z')
        path.svg_arvr_7(fill='none', stroke='#17BBDD', stroke-width='1.8', d='M36.705 107.296c0-6.6 4.701-11.957 10.491-11.957 5.791 0 10.492 5.357 10.492 11.957 0 6.599-4.701 11.956-10.492 11.956-5.79 0-10.491-5.357-10.491-11.956z')
        path.svg_arvr_8(fill='#F3EB7D', stroke='#F3EB7D', stroke-width='1', d='M23.368 97.076c0-4.605 3.739-8.344 8.344-8.344 4.605 0 8.344 3.739 8.344 8.344 0 4.605-3.739 8.344-8.344 8.344-4.605 0-8.344-3.739-8.344-8.344z')
        path.svg_arvr_9(fill='#F3EB7D', stroke='#F3EB7D', stroke-width='1', d='M83.578 97.076c0-4.605 3.739-8.344 8.344-8.344 4.605 0 8.344 3.739 8.344 8.344 0 4.605-3.739 8.344-8.344 8.344-4.605 0-8.344-3.739-8.344-8.344z')
        path.svg_arvr_10(fill='#123D6E', stroke='#123D6E', stroke-width='1', d='M152.521 77.895c-.729-18.164-20.949-39.218-37.079-39.521l-15.09.137c11.54.508 33.23 19.764 34.69 36.665')
        path.svg_arvr_11(fill='#F3EB7D', stroke='#F3EB7D', stroke-width='1', d='M64.402 73.436c1.25-16.626 19.81-34.623 35.94-34.926l15.09-.136c-11.54.508-32.88 18.328-34.35 35.229')
        path.svg_arvr_12(fill='#F3EB7D', stroke='#F3EB7D', stroke-width='1', d='M132.122 100.59h45v14.901h-45V100.59z')
        path.svg_arvr_13(fill='#F3EB7D', stroke='#F3EB7D', stroke-width='1', d='M2.865 100.59h17.433v14.901H2.865V100.59z')
</template>

<script>
export default {
  name: "IconArVr",
  components: {}
};
</script>

<style>
.svg_arvr_0 {
  stroke-dasharray: 368 370;
  stroke-dashoffset: 369;
}

.svg_arvr_1 {
  stroke-dasharray: 368 370;
  stroke-dashoffset: 369;
}

.svg_arvr_2 {
  stroke-dasharray: 144 146;
  stroke-dashoffset: 145;
}

.svg_arvr_3 {
  stroke-dasharray: 100 102;
  stroke-dashoffset: 101;
}

.svg_arvr_4 {
  stroke-dasharray: 71 73;
  stroke-dashoffset: 72;
}

.svg_arvr_5 {
  stroke-dasharray: 144 146;
  stroke-dashoffset: 145;
}

.svg_arvr_6 {
  stroke-dasharray: 100 102;
  stroke-dashoffset: 101;
}

.svg_arvr_7 {
  stroke-dasharray: 71 73;
  stroke-dashoffset: 72;
}

.svg_arvr_8 {
  stroke-dasharray: 53 55;
  stroke-dashoffset: 54;
}

.svg_arvr_9 {
  stroke-dasharray: 53 55;
  stroke-dashoffset: 54;
}

.svg_arvr_10 {
  stroke-dasharray: 127 129;
  stroke-dashoffset: 128;
}

.svg_arvr_11 {
  stroke-dasharray: 122 124;
  stroke-dashoffset: 123;
}

.svg_arvr_12 {
  stroke-dasharray: 120 122;
  stroke-dashoffset: 121;
}

.svg_arvr_13 {
  stroke-dasharray: 65 67;
  stroke-dashoffset: 66;
}
</style>



