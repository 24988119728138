<template lang="pug">
  div
    svg(xmlns='http://www.w3.org/2000/svg', viewBox='0 0 180 180', fill-rule='evenodd', stroke-width='1', stroke-linejoin='round', stroke-miterlimit='1.4', fill-opacity='0')
        path.svg_dev_0(fill='#123D6E', stroke='#123D6E', d='M160.619 105.149h-60.467l18.665 41.911 41.865-28.97-.063-12.941z')
        path.svg_dev_1(fill='#F3B85C', stroke='#F3B85C', d='M59.493 105.083h101.19L118.81 147.06H17.621l41.872-41.977z')
        path.svg_dev_2(fill='#F3EB7D', stroke='#F3EB7D', d='M60.044 106.289h17.239l-10.399 10.425H49.645l10.399-10.425z')
        path.svg_dev_3(fill='#F3EB7D', stroke='#F3EB7D', d='M48.249 118.467h17.239l-10.399 10.425H37.85l10.399-10.425z')
        path.svg_dev_4(fill='#F3EB7D', stroke='#F3EB7D', d='M36.617 130.489h17.238l-10.399 10.425H26.217l10.4-10.425z')
        path.svg_dev_5(fill='#F3EB7D', stroke='#F3EB7D', d='M79.837 106.289h17.239l-10.4 10.425H69.438l10.399-10.425z')
        path.svg_dev_6(fill='#F3EB7D', stroke='#F3EB7D', d='M68.042 118.467h17.239l-10.399 10.425H57.643l10.399-10.425z')
        path.svg_dev_7(fill='#F3EB7D', stroke='#F3EB7D', d='M87.835 118.467h17.239l-10.399 10.425H77.436l10.399-10.425z')
        path.svg_dev_8(fill='#F3EB7D', stroke='#F3EB7D', d='M76.202 130.489h17.239l-10.399 10.425H65.803l10.399-10.425z')
        path.svg_dev_9(fill='#F3EB7D', stroke='#F3EB7D', d='M119.423 106.289h17.238l-10.399 10.425h-17.238l10.399-10.425z')
        path.svg_dev_10(fill='#F3EB7D', stroke='#F3EB7D', d='M95.995 130.489h17.239l-10.399 10.425H85.596l10.399-10.425z')
        path.svg_dev_11(fill='#F3EB7D', stroke='#F3EB7D', d='M139.216 106.289h17.238l-10.399 10.425h-17.239l10.4-10.425z')
        path.svg_dev_12(fill='#F3EB7D', stroke='#F3EB7D', d='M127.421 118.467h17.238l-10.399 10.425h-17.238l10.399-10.425z')
        path.svg_dev_13(fill='#F3EB7D', stroke='#F3EB7D', d='M115.788 130.489h17.239l-10.4 10.425h-17.238l10.399-10.425z')
        path.svg_dev_14(fill='#E85E36', stroke='#E85E36', d='M60.043 34.936H160.68v65.728H60.043V34.936z')
        path.svg_dev_15(fill='#F3B85C', stroke='#F3B85C', d='M160.683 100.664H60.043V34.936h100.64v65.728zM61.932 36.82v61.96h96.87V36.82h-96.87z')
        path.svg_dev_16(fill='#F3EB7D', stroke='#F3EB7D', d='M96.56 66.898l28.564-7.654 2.574 9.608-28.564 7.653-2.574-9.607z')
        path.svg_dev_17(fill='#F3EB7D', stroke='#F3EB7D', d='M75.83 72.452c.561-5.713 4.58-10.755 10.444-12.326 7.558-2.025 15.336 2.468 17.362 10.025 2.025 7.558-2.465 15.337-10.024 17.363-5.863 1.571-11.865-.785-15.208-5.453l15.924-4.267-2.575-9.608-15.923 4.266z')
        path.svg_dev_18(fill='#F3EB7D', stroke='#F3EB7D', d='M142.622 54.556c-3.343-4.668-9.345-7.024-15.208-5.453-7.559 2.025-12.048 9.805-10.023 17.362 2.025 7.558 9.803 12.051 17.362 10.025 5.863-1.571 9.883-6.612 10.443-12.326l-15.923 4.267-2.575-9.609 15.924-4.266z')
</template>

<script>
export default {
  name: "IconDev",
  components: {}
};
</script>

<style>
.svg_dev_0 {
  stroke-dasharray: 171 173;
  stroke-dashoffset: 172;
}

.svg_dev_1 {
  stroke-dasharray: 321 323;
  stroke-dashoffset: 322;
}

.svg_dev_2 {
  stroke-dasharray: 64 66;
  stroke-dashoffset: 65;
}

.svg_dev_3 {
  stroke-dasharray: 64 66;
  stroke-dashoffset: 65;
}

.svg_dev_4 {
  stroke-dasharray: 64 66;
  stroke-dashoffset: 65;
}

.svg_dev_5 {
  stroke-dasharray: 64 66;
  stroke-dashoffset: 65;
}

.svg_dev_6 {
  stroke-dasharray: 64 66;
  stroke-dashoffset: 65;
}

.svg_dev_7 {
  stroke-dasharray: 64 66;
  stroke-dashoffset: 65;
}

.svg_dev_8 {
  stroke-dasharray: 64 66;
  stroke-dashoffset: 65;
}

.svg_dev_9 {
  stroke-dasharray: 64 66;
  stroke-dashoffset: 65;
}

.svg_dev_10 {
  stroke-dasharray: 64 66;
  stroke-dashoffset: 65;
}

.svg_dev_11 {
  stroke-dasharray: 64 66;
  stroke-dashoffset: 65;
}

.svg_dev_12 {
  stroke-dasharray: 64 66;
  stroke-dashoffset: 65;
}

.svg_dev_13 {
  stroke-dasharray: 64 66;
  stroke-dashoffset: 65;
}

.svg_dev_14 {
  stroke-dasharray: 333 335;
  stroke-dashoffset: 334;
}

.svg_dev_15 {
  stroke-dasharray: 651 653;
  stroke-dashoffset: 652;
}

.svg_dev_16 {
  stroke-dasharray: 80 82;
  stroke-dashoffset: 81;
}

.svg_dev_17 {
  stroke-dasharray: 122 124;
  stroke-dashoffset: 123;
}

.svg_dev_18 {
  stroke-dasharray: 122 124;
  stroke-dashoffset: 123;
}
</style>








