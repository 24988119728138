<template lang="pug">
  div
    svg(xmlns='http://www.w3.org/2000/svg', fill-rule='evenodd', stroke-linejoin='round', stroke-miterlimit='1.4', clip-rule='evenodd', viewBox='0 0 180 181')
        g(stroke-width='.2', stroke-linecap='round')
            path.svg_edu_0(stroke='#f3eb7d', fill='#f3eb7d', fill-opacity='0', d='M139.1 158c0-22.7-21.5-41.1-48.1-41.1s-48.1 18.4-48.1 41H139z')
            path.svg_edu_1(stroke='#f3b85c', fill='#f3b85c', fill-opacity='0', d='M92 116.9c26.1.5 47.1 18.7 47.1 41h-47v-41z')
            path.svg_edu_2(stroke='#e85e36', fill='#e85e36', fill-opacity='0', d='M121.8 131.3v.2l-14.4 8.7 9.3 9-12 8.6-1-1.6 9.9-7.2-9.4-9v-.2l14.5-8.7-8.5-8 1.4-1.5 10.2 9.7z')
            path.svg_edu_3(stroke='#f3b85c', fill='#f3b85c', fill-opacity='0', d='M74 123l-8.4 8 14.6 8.8v.2l-9.5 9 10 7-1.1 1.7-12-8.4v-.1l9.4-9-14.5-8.7v-.2l10.2-9.7L74 123z')
            path.svg_edu_4(stroke='#123d6e', fill='#123d6e', fill-opacity='0', d='M78.8 122h27v36h-27z')
            path.svg_edu_5(stroke='#f3eb7d', fill='#f3eb7d', fill-opacity='0', d='M92 36.7s-6.4-12.1-19.8-15c0 0 3.8 6.2 3.1 9.7-4-.7-8.2-1-12-.4C51 32.9 41.8 47.4 41.8 47.4s5.3-3 10.8-3.5C42 51.8 32 64.3 34.5 76.6c5-2.8 15.2.8 15.2.8s-4 3.8-4.5 8.4c8.3-2.1 16.2 4.4 16.2 4.4h31.2L92 36.7z')
            path.svg_edu_6(stroke='#f3b85c', fill='#f3b85c', fill-opacity='0', d='M92 36.7s12.4-7.7 24.7-5.7c12.3 1.9 21.5 16.4 21.5 16.4s-5.3-3-10.8-3.5c10.5 7.9 20.5 20.4 18.1 32.7-5-2.8-15.2.8-15.2.8s4 3.8 4.5 8.4c-7.3-1.8-16.2 4.4-16.2 4.4H92V36.7zM92 124.1V47.4H76c-8.6 0-15.6 7-15.6 15.6v45.5c0 8.6 7 15.6 15.6 15.6h16z')
            path.svg_edu_7(stroke='#e85e36', fill='#e85e36', fill-opacity='0', d='M107.2 47.4c8.6 0 15.6 7 15.6 15.6v45.5c0 8.6-7 15.6-15.6 15.6H92V47.4h15.2z')
            path.svg_edu_8(stroke='#f3eb7d', fill='#f3eb7d', fill-opacity='0', d='M110.6 113.7H73.2l6.4-8.4a10 10 0 0 1 8-4H97c2.6 0 5 1.3 6.7 3.4l6.8 9z')
            path.svg_edu_9(stroke='#0c1f39', fill='#0c1f39', fill-opacity='0', d='M82 90.2a6 6 0 0 0-6.2-6 6 6 0 0 0-6.2 6H82zM113.4 90.2a6 6 0 0 0-6.1-6 6 6 0 0 0-6.2 6h12.3z')
            path.svg_edu_10(stroke='#f3eb7d', fill='#f3eb7d', fill-opacity='0', d='M107.2 73.5c-6.7 0-12.4 4.7-13.9 11 0 0 0 0 0 0-1-.7-2-.5-2.8 0-.2.1-.3.2-.5.4-1.4-6.5-7.1-11.3-14-11.3-7.9 0-14.3 6.4-14.3 14.3 0 7.9 6.4 14.3 14.3 14.3 7.9 0 14.3-6.4 14.3-14.3 0-.2 0-.4 0-.5l1-1c.3-.2.6-.3.9 0l.7.9c0 .2 0 .4 0 .7 0 7.9 6.4 14.3 14.3 14.3 7.9 0 14.3-6.4 14.3-14.3C121.5 80 115.1 73.5 107.2 73.5zM76.1 100.5c-6.9 0-12.4-5.6-12.4-12.4 0-6.9 5.6-12.4 12.4-12.4s12.4 5.6 12.4 12.4C88.5 94.9 83 100.5 76.1 100.5zM107.4 100.5c-6.9 0-12.4-5.6-12.4-12.4 0-6.9 5.6-12.4 12.4-12.4s12.4 5.6 12.4 12.4C119.8 94.9 114.2 100.5 107.4 100.5z')
</template>

<script>
export default {
  name: "IconEdu",
  components: {}
};
</script>

<style>
.svg_edu_0 {
  stroke-dasharray: 237 239;
  stroke-dashoffset: 238;
}
.svg_edu_1 {
  stroke-dasharray: 158 160;
  stroke-dashoffset: 159;
}
.svg_edu_2 {
  stroke-dasharray: 117 119;
  stroke-dashoffset: 118;
}
.svg_edu_3 {
  stroke-dasharray: 118 120;
  stroke-dashoffset: 119;
}
.svg_edu_4 {
  stroke-dasharray: 126 128;
  stroke-dashoffset: 127;
}
.svg_edu_5 {
  stroke-dasharray: 254 256;
  stroke-dashoffset: 255;
}
.svg_edu_6 {
  stroke-dasharray: 431 433;
  stroke-dashoffset: 432;
}
.svg_edu_7 {
  stroke-dasharray: 202 204;
  stroke-dashoffset: 203;
}
.svg_edu_8 {
  stroke-dasharray: 86 88;
  stroke-dashoffset: 87;
}
.svg_edu_9 {
  stroke-dasharray: 64 66;
  stroke-dashoffset: 65;
}
.svg_edu_10 {
  stroke-dasharray: 338 340;
  stroke-dashoffset: 339;
}
</style>



