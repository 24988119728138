<template lang="pug">
  div
    svg(xmlns='http://www.w3.org/2000/svg', viewBox='0 0 180 180', fill-rule='evenodd', stroke-linejoin='round', stroke-miterlimit='1.4')
        path.svg_iot_0(fill-opacity='0', stroke='#F3EB7D', stroke-width='4.5', d='M134.522 117.914l.063-94.739')
        path.svg_iot_1(fill-opacity='0', stroke='#F3EB7D', stroke-width='1', fill='#F3EB7D', d='M129.522 20.104c0-2.884 2.342-5.226 5.227-5.226 2.884 0 5.226 2.342 5.226 5.226 0 2.885-2.342 5.227-5.226 5.227-2.885 0-5.227-2.342-5.227-5.227z')
        path.svg_iot_2(fill-opacity='0', stroke='#E85E36', stroke-width='1', fill='#E85E36', d='M52.21 164.656c-3.292.779-7.206.343-9.275.343-7.872 0-14.268-8.195-14.268-18.291 0-10.094 6.396-18.29 14.268-18.29 1.853 0 8.394-.529 10.211.377v.001c6.306 1.891 11.002 9.152 11.002 17.811 0 9.08-5.164 16.625-11.938 18.049z')
        path.svg_iot_3(fill-opacity='0', stroke='#123D6E', stroke-width='1', fill='#123D6E', d='M43.767 146.61c0-8.77 4.468-15.89 9.972-15.89s9.972 7.12 9.972 15.89-4.468 15.89-9.972 15.89-9.972-7.12-9.972-15.89z')
        path.svg_iot_4(fill-opacity='0', stroke='#172B4D', stroke-width='1', fill='#172B4D', d='M61.777 121.695h79.362v30.941H61.777v-30.941z')
        path.svg_iot_5(fill-opacity='0', stroke='#F3EB7D', stroke-width='1', fill='#F3EB7D', d='M47.637 123.325h17.72l9.645 29.311h-17.72l-9.645-29.311z')
        path.svg_iot_6(fill-opacity='0', stroke='#123D6E', stroke-width='1', fill='#123D6E', d='M89.737 117.18h57.708v7.511H89.737v-7.511z')
        path.svg_iot_7(fill-opacity='0', stroke='#F3EB7D', stroke-width='1', fill='#F3EB7D', d='M30.923 117.18h58.815v7.511H30.923v-7.511z')
        path.svg_iot_8(fill-opacity='0', stroke='#F3EB7D', stroke-width='16', d='M78.163 121.696l21.326-58.345')
        path.svg_iot_9(fill-opacity='0', stroke='#123D6E', stroke-width='1', fill='#123D6E', d='M124.351 76.08c-.73 14.539-9.397 26.027-19.954 26.027H53.996c-10.556-.005-19.21-11.492-19.941-26.027h90.296z')
        path.svg_iot_10(fill-opacity='0', stroke='#F3EB7D', stroke-width='1', fill='#F3EB7D', d='M34.042 76.08c-.025-.665-.05-1.338-.05-2.015 0-15.477 8.969-28.043 20.017-28.043.088 0 .189.002.29.003l50.098-.003c11.035 0 20.005 12.566 20.005 28.043 0 .677-.013 1.35-.051 2.015H34.042z')
        path.svg_iot_11(fill-opacity='0', stroke='#E85E36', stroke-width='1', fill='#E85E36', d='M35.925 74.067c0-13.986 8.102-25.341 18.082-25.341s18.082 11.355 18.082 25.341c0 13.987-8.102 25.342-18.082 25.342S35.925 88.054 35.925 74.067z')
        path.svg_iot_12(fill-opacity='0', stroke='#0C1F39', stroke-width='1', fill='#0C1F39', d='M50.621 74.504c0-7.569 4.247-13.714 9.477-13.714 5.231 0 9.477 6.145 9.477 13.714 0 7.57-4.246 13.715-9.477 13.715-5.23 0-9.477-6.145-9.477-13.715z')
        path.svg_iot_13(fill-opacity='0', stroke='#F3EB7D', stroke-width='1', fill='#F3EB7D', d='M44.087 63.248c0 3.287 1.405 6.324 3.685 7.967 2.281 1.643 5.09 1.643 7.371 0 2.28-1.643 3.685-4.68 3.685-7.967s-1.405-6.324-3.685-7.967c-2.281-1.643-5.09-1.643-7.371 0-2.28 1.643-3.685 4.68-3.685 7.967')
        path.svg_iot_14(fill-opacity='0', stroke='#E85E36', stroke-width='1', fill='#E85E36', d='M139.423 164.345c-3.292.779-7.206.344-9.275.344-7.871 0-14.268-8.196-14.268-18.292 0-10.094 6.397-18.29 14.268-18.29 1.854 0 8.394-.529 10.211.377v.001c6.306 1.891 11.003 9.152 11.003 17.811 0 9.081-5.164 16.625-11.939 18.049z')
        path.svg_iot_15(fill-opacity='0', stroke='#123D6E', stroke-width='1', fill='#123D6E', d='M130.975 146.299c0-8.77 4.468-15.89 9.972-15.89s9.972 7.12 9.972 15.89-4.468 15.89-9.972 15.89-9.972-7.12-9.972-15.89z')
        path.svg_iot_16(fill-opacity='0', stroke='#E85E36', stroke-width='1', fill='#E85E36', d='M100.083 164.656c-3.293.779-7.206.344-9.275.344-7.872 0-14.269-8.196-14.269-18.292 0-10.094 6.397-18.29 14.269-18.29 1.853 0 8.393-.529 10.21.377v.001c6.307 1.891 11.003 9.152 11.003 17.811 0 9.081-5.164 16.625-11.938 18.049z')
        path.svg_iot_17(fill-opacity='0', stroke='#123D6E', stroke-width='1', fill='#123D6E', d='M91.634 146.609c0-8.77 4.469-15.89 9.972-15.89 5.504 0 9.973 7.12 9.973 15.89s-4.469 15.89-9.973 15.89c-5.503 0-9.972-7.12-9.972-15.89z')
</template>

<script>
export default {
  name: "IconIot",
  components: {}
};
</script>

<style>
.svg_iot_0 {
  stroke-dasharray: 95 97;
  stroke-dashoffset: 96;
}
.svg_iot_1 {
  stroke-dasharray: 33 35;
  stroke-dashoffset: 34;
}
.svg_iot_2 {
  stroke-dasharray: 117 119;
  stroke-dashoffset: 118;
}
.svg_iot_3 {
  stroke-dasharray: 83 85;
  stroke-dashoffset: 84;
}
.svg_iot_4 {
  stroke-dasharray: 221 223;
  stroke-dashoffset: 222;
}
.svg_iot_5 {
  stroke-dasharray: 98 100;
  stroke-dashoffset: 99;
}
.svg_iot_6 {
  stroke-dasharray: 131 133;
  stroke-dashoffset: 132;
}
.svg_iot_7 {
  stroke-dasharray: 133 135;
  stroke-dashoffset: 134;
}
.svg_iot_8 {
  stroke-dasharray: 63 65;
  stroke-dashoffset: 64;
}
.svg_iot_9 {
  stroke-dasharray: 213 215;
  stroke-dashoffset: 214;
}
.svg_iot_10 {
  stroke-dasharray: 221 223;
  stroke-dashoffset: 222;
}
.svg_iot_11 {
  stroke-dasharray: 138 140;
  stroke-dashoffset: 139;
}
.svg_iot_12 {
  stroke-dasharray: 74 76;
  stroke-dashoffset: 75;
}
.svg_iot_13 {
  stroke-dasharray: 53 55;
  stroke-dashoffset: 54;
}
.svg_iot_14 {
  stroke-dasharray: 117 119;
  stroke-dashoffset: 118;
}
.svg_iot_15 {
  stroke-dasharray: 83 85;
  stroke-dashoffset: 84;
}
.svg_iot_16 {
  stroke-dasharray: 117 119;
  stroke-dashoffset: 118;
}
.svg_iot_17 {
  stroke-dasharray: 83 85;
  stroke-dashoffset: 84;
}
</style>



